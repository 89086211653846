<template>
  <base-modal
    :modal-title="
      `Collaboration ${collaborationForm?.collaborationId ? `- ${collaborationForm?.collaborationId}`:''}`+
      `${collaborationForm?.status === 2 ? ' - [ CLOSED ]':''}`
    "
    popup-class="modal-xl mb-36"
    @hidden="handleCancel()"
  >
    <template #modalBody>
      <div class="px-12 py-8">
        <brand-assurance-collaboration-form
          @hideCollabModal="handleCancel()"
          @collaborationUpdated="$emit('collaborationUpdated')"
        />
      </div>
    </template>
  </base-modal>
</template>

<script>
import { defineAsyncComponent } from 'vue';
import useCollaborationForm from './collaborationForm.js';

export default {
    name: 'BrandAssuranceCollaborationFormModal',

    components: {
        BrandAssuranceCollaborationForm: defineAsyncComponent(() => import('./BrandAssuranceCollaborationForm.vue')),
        BaseModal: defineAsyncComponent(() => import('@/components/generic-components/BaseModal.vue'))
    },

    emits: [
        'hide',
        'collaborationUpdated'
    ],

    setup (props, { emit }) {
        const { collaborationForm } = useCollaborationForm();

        // modal logic
        const handleCancel = () => {
            emit('hide');
        };

        return {
            collaborationForm,
            handleCancel
        };
    }
};
</script>
